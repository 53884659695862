import React from 'react';
import PropTypes from 'prop-types';
import style from '../../../scss/components/form.module.scss';
import buttonStyle from '../../../scss/components/buttons/button.module.scss';

const GoToProductChangeRequestButton = ({ entity }) => {
    if (!entity) return null;

    const productChangeId = entity?.product_change_request_id;
    const productId = entity?.product_id;

    if (productChangeId == null && productId == null) return null;

    const url =
        productId != null
            ? `/products/${productId}/show`
            : `/product-change-requests/${productChangeId}/show`;

    const text =
        productId != null ? 'Go to Product' : 'Go to Product Change Request';

    return (
        <div className={style.formGroup} style={{ marginTop: '2rem' }}>
            <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className={buttonStyle.basic}
            >
                {text}
            </a>
        </div>
    );
};

export default GoToProductChangeRequestButton;

GoToProductChangeRequestButton.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
